import React, { useState, useEffect } from "react";
import { FaUpload } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaFileCircleCheck } from "react-icons/fa6";
import { ImSpinner9 } from "react-icons/im";
import Select from 'react-select'
import config from '../../src/config';
// import { useAuthState } from "../Context/AuthenticationContext";
import { useSharedState } from "../Context/SharedStateContext";
import { toast } from "react-toastify";

const Supportpage = () => {
  // const {userEmailId,CustomerId}=useAuthState();
  const CustomerId = localStorage.getItem('user');
  const userEmailId = localStorage.getItem('email');
  const { Chatbots } = useSharedState();
  const dyanamicbackendUrl = config[process.env.NODE_ENV].backendUrl;
  const dyanamicBotQueryUrl = config[process.env.NODE_ENV].BotQueryUrl;


  const [formData, setFormData] = useState({
    email: userEmailId,
    affectedChatbots: [],
    problemArea: "",
    severity: "",
    subject: "",
    description: "",
    attachments: [],
    status: false,
  });
  const [Error, setError] = useState({
    email: "",
    problemArea: "",
    severity: "",
    subject: "",
    description: "",
  });
  const [validationError, setvalidationError] = useState("");
  const severity = [{ value: '', label: 'Select severity', name: 'Select severity' },
  { value: 'Low', label: 'Low', name: 'severity' },
  { value: 'Medium', label: 'Medium', name: 'severity' },
  { value: 'High', label: 'High', name: 'severity' }]
  const [selectSeverity, setselectSeverity] = useState({ value: '', label: 'Select severity', name: 'Select severity' });
  const problemArea = [{ value: '', label: 'Select issue', name: 'Select issue' },
  { value: 'Technical', label: 'Technical', name: 'problemArea' },
  { value: 'Functional', label: 'Functional', name: 'problemArea' },
  { value: 'Other', label: 'Other', name: 'problemArea' }]
  const [selectProblemarea, setselectProblemarea] = useState({ value: '', label: 'Select issue', name: 'Select issue' })
  const [chatbots, setchatbots] = useState([]);

  // Retrieving all chatbots
  useEffect(() => {
    const getChatbots = async () => {
      try {
        const response = await fetch(
          `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/getallchatbots`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ customerId: CustomerId }),
          }
        );
        const chatbots = await response.json();
        const options = chatbots.map((value) => {
          return {
            value: value.chatbotName,
            label: value.chatbotName,
            chatbotId: value.chatbotId,
          };
        });
        setchatbots(options);
      } catch (error) {
        console.error({ error: error.message });
      }
    };
    if (Chatbots.length > 0) {
      const options = Chatbots.map((value) => {
        return {
          value: value.chatbotName,
          label: value.chatbotName,
          chatbotId: value.chatbotId,
        };
      });
      setchatbots(options);
    }
    else
      getChatbots();
  }, [dyanamicbackendUrl, CustomerId, Chatbots])


  const validateFileSize = (files) => {
    const maxSize = 15 * 1024 * 1024;
    return files.every((file) => file.size <= maxSize);
  };
  const handleChange = (event) => {
    setButtonStatus(true);
    const { name, value, type, files, checked } = event.target;
    const newValue =
      type === "checkbox"
        ? checked
        : type === "file"
          ? Array.from(files)
          : type === "select-multiple"
            ? Array.from(event.target.selectedOptions).map((option) => option.value)
            : value;
    setError({
      email: "",
      problemArea: "",
      severity: "",
      subject: "",
      description: "",
    });

    if (name === "attachments") {
      if (validateFileSize(newValue)) {
        setFormData((prevState) => ({
          ...prevState,
          attachments: [...prevState.attachments, ...newValue],
        }));
      } else {
        setvalidationError(
          "File size exceeds 15MB. Please select a smaller file."
        );
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  //Checking every field should be filled

  const isBlank = () => {
    setError({
      email: "",
      problemArea: "",
      severity: "",
      subject: "",
      description: "",
    });
    if (formData.email === "") {
      setError((prev) => ({
        ...prev,
        email: "Please add an email",
      }));
      return true;
    } else if (formData.problemArea === "") {
      setError((prev) => ({
        ...prev,
        problemArea: "Please select Issue",
      }));
      return true;
    } else if (formData.severity === "") {
      setError((prev) => ({
        ...prev,
        severity: "Please select Severity",
      }));
      return true;
    } else if (formData.subject === "") {
      setError((prev) => ({
        ...prev,
        subject: "Please provide the Subject",
      }));
      return true;
    } else if (formData.description === "") {
      setError((prev) => ({
        ...prev,
        description: "Please provide Description",
      }));
      return true;
    }
    return false;
  };
  // Submitting the form-data
  const validateEmail = (email) => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isBlank()) return;
  
    if (!validateEmail(formData.email)) {
      setError((prev) => ({
        ...prev,
        email: 'Please Enter valid email address..'
      }))
      return;
    }
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      const extractValues = formData.affectedChatbots
        .map((values) => {
          return values.chatbotId;
        })
        .join(",");
      formDataToSend.append("attachments", formData.attachments);
      formDataToSend.append("customerId", CustomerId);
      formDataToSend.append("chatbotId", extractValues);

      formDataToSend.append("emailId", formData.email);
      formDataToSend.append("severity", formData.severity);
      formDataToSend.append("issueType", formData.problemArea);
      formDataToSend.append("subject", formData.subject);
      formDataToSend.append("description", formData.description);

      const response = await fetch(
        `${dyanamicBotQueryUrl}create-support-request/`,
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        setFormData((prev)=>({
          ...prev,
          affectedChatbots: [],
          problemArea: "",
          severity: "",
          subject: "",
          description: "",
          attachments: [],
          status: false,
        }));
        setselectSeverity({ value: '', label: 'Select severity', name: 'Select severity' });
        setselectProblemarea({ value: '', label: 'Select issue', name: 'Select issue' })

       toast.success( "Your ticket has been submitted successfully. Our support team will contact you ASAP. ");
      } else {
       toast.error("An error while saving..")
        setLoading(false);
        setButtonStatus((prev) => !prev);
      }
    } catch (error) {
      toast.error("An error while saving..")
      setLoading(false);
      setButtonStatus((prev) => !prev);
      console.error({ error: error.message });
    } finally {
      setLoading(false);

      setButtonStatus((prev) => !prev);
    }
  };
  const deleteFile = (index) => {
    const updatedAttachments = [...formData.attachments];
    updatedAttachments.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      attachments: updatedAttachments,
    }));
  };
  const handleMultiselect = (values) => {
    setButtonStatus(true);
    setFormData((prevState) => ({
      ...prevState,
      affectedChatbots: values || [],
    }));
  };
  const handleSingleselect = (value) => {
    setButtonStatus(true);
    setError({
      email: "",
      problemArea: "",
      severity: "",
      subject: "",
      description: "",
    });
    setFormData((prevState) => ({
      ...prevState,
      [value.name]: value.value || "",
    }));
    if (value.name === 'severity') {
      setselectSeverity(value);
    }
    else if (value.name === 'problemArea')
      setselectProblemarea(value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.375rem",
      boxShadow: state.isFocused
        ? "0 0 0 2px rgba(132, 204, 22, 0.25)"
        : "none",
      borderColor: state.isFocused
        ? "rgb(54 83 20 / 1)"
        : "rgb(132, 204, 22, 0.25)",
      color: "#4B5563",
      "&:hover": {
        borderColor: "rgb(54 83 20 / 1)", // border-lime-500/25
      },
    }),
  };

  const [buttonStatus, setButtonStatus] = useState(false);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const handleChange = () => {
      setButtonStatus(true);
      document.removeEventListener("change", handleChange); // Remove the event listener after it's triggered once
    };

    document.addEventListener("change", handleChange);

    return () => {
      document.removeEventListener("change", handleChange); // Cleanup: remove the event listener when component unmounts
    };
  }, []);

  return (
    <div className=" w-11/12  md:w-3/4 mt-28 md:mt-24 mb-4 pt-4 rounded-md bg-white shadow-xl  mx-auto">
     
      <div className="flex flex-col gap-1 mb-6">
        <h1 className="heading flex items-center py-2">
          <span className="flex-1 border-b border-lime-500/25 mx-4"></span>
          <span className="text-center text-lg sm:text-xl  font-medium text-gray-800 opacity-80">
            Contact Customer Support
          </span>
          <span className="flex-1 border-b border-lime-500/25 mx-4"></span>
        </h1>
        <div className="flex justify-center items-center">
          <span className="text-lime-800 font-medium text-center text-xs md:text-sm ">
            {" "}
            Need some help? Submit a ticket and we'll get back to you as soon as
            possible!
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="upper  border-lime-500/25 mt-8">
          <div className="flex gap-5 mt-3 ">
            <div className="mb-4 w-[49%] ml-4">
              <label
                htmlFor="email"
                className="inline text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <span className="text-[#ff130b] pl-1">*</span>
              <input
                type="email"
                id="email"
                name="email"
                disabled={Loading}
                className={`mt-1 block w-full rounded-md shadow-sm px-3 py-1.5 text-gray-800 ${Loading ? "bg-[#f4f4f4]" : ""
                  }   border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleChange}
              />
              {Error.email && (
                <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
                  {Error.email}
                </p>
              )}
            </div>

            <div className="mb-4 w-[49%] mr-4">
              <div className="mb-1">
                <label
                  htmlFor="problemArea"
                  className="inline text-sm  font-medium text-gray-700"
                >
                  Select Issue
                </label>
                <span className="text-[#ff130b] pl-1">*</span>
              </div>
              <Select
                name="problemArea"
                defaultValue={problemArea[0]}
                value={selectProblemarea}
                options={problemArea}
                onChange={(selectedOption) =>
                  handleSingleselect(selectedOption)
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(132 204 22 / 0.25)",
                    primary: "#4D7C0F",
                  },
                })}
                styles={customStyles}
                isSearchable={true}
                isDisabled={Loading}
              />
              {Error.problemArea && (
                <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
                  {Error.problemArea}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="middle flex border-b-[1px] border-lime-500/25 gap-5  ">
          <div className="mb-4 w-[69%] ml-4">
            <label
              htmlFor="RelatedChatbot"
              className="block text-sm mb-[5px] font-medium text-gray-700"
            >
              Select Chatbots
            </label>
            <Select
              defaultValue={chatbots[0]}
              options={chatbots}
              onChange={handleMultiselect}
              value={formData.affectedChatbots}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(132 204 22 / 0.25)",
                  primary: "rgb(132 204 22 / 0.1)",
                },
              })}
              styles={customStyles}
              isSearchable={true}
              isDisabled={Loading}
              isMulti
            />
          </div>

          <div className="mb-4 w-[29%] mr-4">
            <div className="mb-0.5">
              <label
                htmlFor="severity"
                className="inline text-sm font-medium text-gray-700"
              >
                Severity
              </label>
              <span className="text-[#ff130b] pl-1">*</span>
            </div>
            <Select
              name="severity"
              defaultValue={severity[0]}
              value={selectSeverity}
              options={severity}
              onChange={(selectedOption) => handleSingleselect(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(132 204 22 / 0.25)",
                  primary: "#4D7C0F",
                },
              })}
              styles={customStyles}
              isDisabled={Loading}
              isSearchable={true}
            />
            {Error.severity && (
              <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
                {Error.severity}
              </p>
            )}
          </div>
        </div>

        <div className="mb-4  mx-4 my-3">
          <label
            htmlFor="subject"
            className="inline text-sm font-medium text-gray-700"
          >
            Subject
          </label>
          <span className="text-[#ff130b] pl-1">*</span>
          <input
            type="text"
            id="subject"
            name="subject"
            className={`mt-1 block w-full rounded-md ${Loading ? "bg-[#f4f4f4]" : ""
              }   shadow-sm px-3 py-1.5 text-gray-800    border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
            placeholder="Enter the subject"
            value={formData.subject}
            onChange={handleChange}
            disabled={Loading}
          />
          {Error.subject && (
            <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
              {Error.subject}
            </p>
          )}
        </div>

        <div className="mb-6 mx-4">
          <label
            htmlFor="description"
            className="inline text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <span className="text-[#ff130b] pl-1">*</span>
          <textarea
            id="description"
            name="description"
            rows="4"
            className={`mt-1 block w-full rounded-md ${Loading ? "bg-[#f4f4f4]" : ""
              }  shadow-sm px-3 py-2 text-gray-800    border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
            placeholder="Enter the description"
            value={formData.description}
            onChange={handleChange}
            disabled={Loading}
          ></textarea>
          {Error.description && (
            <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
              {Error.description}
            </p>
          )}
          <div className="w-full mt-2 flex  items-center">
            <span className="text-xs text-gray-700  opacity-80">
              {" "}
              Please add a description as it will allow us to debug and fix the
              issue better
            </span>
          </div>
        </div>
        <div className="my-4 mx-4 flex flex-col gap-3  ">
          <div className="flex justify-center items-center">
            <input
              type="file"
              id="attachments"
              name="attachments"
              className="hidden"
              onChange={handleChange}
              multiple
              disabled={Loading}
            />

            <button
              type="button"
              className="py-1 px-2 w-1/2 md:w-[25%] rounded-md text-sm bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
              onClick={() => document.getElementById("attachments").click()}
            >
              <FaUpload size={15} className="inline mr-2" />
              Attachments
            </button>
          </div>
          {formData.attachments.length > 0 && (
            <>
              <ul>
                {formData.attachments.map((file, index) => (
                  <div className="flex justify-between" key={index}>
                    <li
                      key={index}
                      className="text-sm font-medium text-gray-700 opacity-90"
                    >
                      {file.name}
                    </li>
                    <div className="flex justify-center items-center gap-1">
                      <FaFileCircleCheck className="text-md text-lime-800" />
                      <span className="flex hover:bg-lime-500/25 rounded-md justify-center items-center p-1">
                        <MdDeleteOutline
                          className="inline  text-lg hover:text-lime-900 text-lime-800 cursor-pointer"
                          onClick={() => deleteFile(index)}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </ul>
              {validationError && (
                <p className="text-[#ff130b] text-sm opacity-80 m-0.5">
                  {validationError}
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex  justify-between">
          <div className="flex justify-end  w-full bg-lime-500/25 rounded-md px-2 py-1.5">
            <div className="button justify-center items-center text-md font-semibold gap-4 flex flex-col sm:flex-row">
              {/* Submit Button */}
              <button
                disabled={!buttonStatus || Loading}
                type="submit"
                className={`px-4 py-0.5 rounded-md text-white border-2 border-transparent font-semibold transition duration-200 ease-in-out ${buttonStatus && !Loading
                  ? "bg-lime-700 hover:border-lime-800 hover:bg-transparent hover:text-lime-800"
                  : "bg-lime-700 opacity-50 cursor-not-allowed"
                  }`}
              >
                {" "}
                Submit
                {Loading ? (
                  <ImSpinner9 className="animate-spin inline ml-1 text-white text-xl" />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Supportpage;
