import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import config from '../../src/config'

const DataSourcesContext = createContext();

export const DataSourcesStateProvider = ({ children }) => {
  const [totalCharacters, setTotalCharacters] = useState(0);
  const [loading, setLoading] = useState(false);
  const [CreateChatbotloading, setCreateChatbotLoading] = useState(false);
  const [UpdateChatbotloading, setUpdateChatbotLoading] = useState(false);
  const [language, setLanguage] = useState("English");
  const [validResponse, setValidResponse] = useState(null);
  const [isBaseUrl, setIsBaseUrl]=useState(false);
  const dyanamicBotQueryUrl=config[process.env.NODE_ENV].BotQueryUrl;
  const [SourceData, setSourceData] = useState({
    FileList: [],
    MediaFiles: [],
    Text: "",
    qaList: [],
    LinkList: [],
    linkchars: 0,
    filechars: 0,
    textchars: 0,
    qaChars: 0,
  });
  const [ChatbotData, setChatbotData] = useState({
    AddedFiles: [],
    AddedMediaFiles: [],
    AddedText: "",
    AddedqaList: [],
    AddedLinkList: [],
    Addedlinkchars: 0,
    Addedfilechars: 0,
    AddedMediaFilechars: 0,
    Addedtextchars: 0,
    AddedqaChars: 0,
  });
  const [AddedCharacters, setAddedCharacters] = useState(0);
  const [progress, setProgress] = useState(0);
  const [CreateChatbotprogress, setCreateChatbotProgress] = useState(0);
  const [UpdateChatbotprogress, setUpdateChatbotProgress] = useState(0);
  const [WebsiteformData, setWebsiteFormData] = useState({
    crawlUrl: "",
    submitUrl: "",
    includeLink: "",
  });
  const personalities = [
    { id: 0, name: "Default", icon: "❓" },
    { id: 1, name: "Financial Expert", icon: "💰" },
    { id: 2, name: "Legal Expert", icon: "⚖️" },
    { id: 3, name: "Medical Expert", icon: "👩‍⚕️" },
    { id: 4, name: "Recruitment Expert", icon: "👔" },
    { id: 5, name: "Career Counsellor", icon: "🚀" },
    { id: 6, name: "Customer Support Agent", icon: "👥" },
    { id: 7, name: "Social Media Marketing Expert", icon: "📱" },
    { id: 8, name: "Travel Expert", icon: "✈️" },
    { id: 9, name: "Technical Support Executive", icon: "🔧" },
    // { id: 10, name: "Farming Expert", icon: "🌾" },
  ];
  const [selectedPersonality, setSelectedPersonality] = useState(
    personalities[0]
  );
  const [customPersonality, setCustomPersonality] = useState("");

  const resetState = () => {
    setTotalCharacters(0);
    setLoading(false);
    setLanguage("English");
    setSourceData({
      FileList: [],
      MediaFiles: [],
      Text: "",
      qaList: [],
      LinkList: [],
      linkchars: 0,
      filechars: 0,
      textchars: 0,
      qaChars: 0,
    });
    setProgress(0);
    setWebsiteFormData({
      crawlUrl: "",
      submitUrl: "",
      includeLink: "",
    });
    setSelectedPersonality(personalities[0]);
    setCustomPersonality("");
  };

  const handleFetchLinks = async (customerId) => {
    const { LinkList } = SourceData;
    const isUrlAlreadyPresent = LinkList.some(
      (link) => link.url === WebsiteformData?.crawlUrl
    );
    const isSitemapAlreadyPresent = LinkList.some(
      (link) => link.url === WebsiteformData?.submitUrl
    );
    // If the URL is already present, prevent fetching links
    if (isUrlAlreadyPresent || isSitemapAlreadyPresent) {
      toast.warn("The url is already fetched");
      return;
    }
    try {
      setLoading(true);
      setProgress(0);
      // Initialize progress
      let currentProgress = 0;
      const maxProgress = 90;
      const increment = 1; // Increment value for each interval
      const interval = 3000; // Interval in milliseconds

      const progressTimer = setInterval(() => {
        // Increment progress
        currentProgress += increment;
        if (currentProgress >= maxProgress) {
          clearInterval(progressTimer); // Stop the progress timer
        }
        setProgress((prevProgress) => prevProgress + increment); // Functional update form
      }, interval);
      // Generate a UUID for correlation_id
      const correlationId = uuidv4();
      const requestBody = {
        customer_id: customerId,
        correlation_id: correlationId,
      };

      if (WebsiteformData?.crawlUrl) {
        setIsBaseUrl(true)
        requestBody.base_url = WebsiteformData?.crawlUrl;
      }
      
      if (WebsiteformData?.submitUrl) {
        setIsBaseUrl(false)
        requestBody.sitemap_url = WebsiteformData?.submitUrl;
      }
      const url = `${dyanamicBotQueryUrl}fetch-links/`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response?.body) return;
      clearInterval(progressTimer); // Stop the progress timer
      const data = await response.json();
      toast.success("Successfully fetched links");
      setSourceData((prevSourceData) => ({
        ...prevSourceData,
        LinkList: [...prevSourceData.LinkList, ...data.links_crawled],
      }));      
    } catch (error) {
      console.error(error.message);
      toast.error("Error fetching links");
    } finally {
      setLoading(false);
      setProgress(95);
      setTimeout(() => {
        setProgress(100);
      }, 2000);
      setWebsiteFormData((prevData) => ({
        ...prevData,
        crawlUrl: "",
        submitUrl:""
      }));
      setIsBaseUrl(false)
    }
  };

  const createChatbot = async (customerId) => {
    setCreateChatbotProgress(0);
    setValidResponse(null);
    setCreateChatbotLoading(true);
    const { FileList, MediaFiles, LinkList, Text } = SourceData;
    // setLoading(true);
    const url = `${dyanamicBotQueryUrl}create-initial-chatbot/`;
    const formData = new FormData();
    FileList.forEach((File) => {
      formData.append("file_list", File.file);
    });
    MediaFiles.forEach((File) => {
      formData.append("media_file_list", File.file);
    });
    if (customPersonality.length > 1) {
      formData.append("personality", customPersonality);
    } else {
      formData.append("personality", selectedPersonality.name);
    }
    formData.append("language", language);
    const metadata = {};
    FileList.forEach((fileData) => {
      metadata[fileData.file.name] = fileData.charCount;
    });
    formData.append("file_list_metadata", JSON.stringify(metadata));
    formData.append("file_count", FileList.length + MediaFiles.length);
    formData.append("char_count", totalCharacters);
    formData.append("customer_id", customerId);
    formData.append(
      "crawled_urls",
      JSON.stringify({ links_crawled: LinkList })
    );
    formData.append("text_content", Text);
    try {
      // Initialize progress
      let currentProgress = 0;
      const maxProgress = 90;
      const increment = 1; // Increment value for each interval
      const interval = 1000; // Interval in milliseconds

      const progressTimer = setInterval(() => {
        // Increment progress
        currentProgress += increment;
        if (currentProgress >= maxProgress) {
          clearInterval(progressTimer); // Stop the progress timer
        }
        setCreateChatbotProgress((prevProgress) => prevProgress + increment); // Functional update form
      }, interval);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      setValidResponse(response.ok);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      clearInterval(progressTimer); // Stop the progress timer
      const data = await response.json();
      toast.success("Successfully created chatbot");
      console.log(data);
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error Creating chatbot");
    } finally {
      setCreateChatbotLoading(false);
      setCreateChatbotProgress(95);
      setTimeout(() => {
        setCreateChatbotProgress(100);
      }, 2000);
    }
  };
  const RetrainChatbot = async (chatbotDetails, customerId) => {
    setUpdateChatbotProgress(0);
    const { AddedFiles, AddedMediaFiles, AddedLinkList, AddedText } =
      ChatbotData;
    const { FileList, MediaFiles, LinkList } = SourceData;

    setUpdateChatbotLoading(true);
    if (chatbotDetails) {
      const url = `${dyanamicBotQueryUrl}update-chatbot/`;
      const formData = new FormData();
      FileList.forEach((File) => {
        formData.append("file_list", File.file);
      });
      MediaFiles.forEach((File) => {
        formData.append("media_file_list", File.file);
      });
      formData.append("language", language);
      const metadata = {};
      FileList.forEach((fileData) => {
        metadata[fileData.file.name] = fileData.charCount;
      });
      formData.append("file_list_metadata", JSON.stringify(metadata));
      formData.append("chatbotId", chatbotDetails?.chatbotId);
      formData.append(
        "file_count",
        FileList.length +
          AddedFiles.length +
          MediaFiles.length +
          AddedMediaFiles.length
      );
      formData.append("char_count", totalCharacters + AddedCharacters);
      formData.append("customerId", customerId);
      formData.append(
        "crawled_urls",
        JSON.stringify({ links_crawled: LinkList })
      );
      formData.append("text_content", AddedText);
      formData.append("previous_file_list", JSON.stringify(AddedFiles));
      formData.append(
        "previous_media_file_list",
        JSON.stringify(AddedMediaFiles)
      );
      formData.append(
        "previous_crawled_urls",
        JSON.stringify({ links_crawled: AddedLinkList })
      );

      formData.append(
        "previous_details",
        JSON.stringify({
          fileNames: chatbotDetails?.fileNames,
          mediaFileNames: chatbotDetails?.mediaFileNames,
          textContent: chatbotDetails?.textContent,
          crawledUrls: chatbotDetails?.crawledUrls,
        })
      );
      try {
        let currentProgress = 0;
        const maxProgress = 90;
        const increment = 1; // Increment value for each interval
        const interval = 1000; // Interval in milliseconds

        const progressTimer = setInterval(() => {
          // Increment progress
          currentProgress += increment;
          if (currentProgress >= maxProgress) {
            clearInterval(progressTimer); // Stop the progress timer
          }
          setUpdateChatbotProgress((prevProgress) => prevProgress + increment); // Functional update form
        }, interval);
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        clearInterval(progressTimer); // Stop the progress timer
        const data = await response.json();
        toast.success("Successfully updated chatbot");
        console.log(data);
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Error updating chatbot");
      } finally {
        setUpdateChatbotLoading(false);
        setUpdateChatbotProgress(95);
        setTimeout(() => {
          setUpdateChatbotProgress(100);
        }, 2000);
      }
    } else {
      toast.warn("Please provide correct chatbot information");
    }
  };

  return (
    <DataSourcesContext.Provider
      value={{
        totalCharacters,
        setTotalCharacters,
        SourceData,
        setSourceData,
        language,
        setLanguage,
        WebsiteformData,
        setWebsiteFormData,
        progress,
        setProgress,
        CreateChatbotprogress,
        UpdateChatbotprogress,
        personalities,
        selectedPersonality,
        setSelectedPersonality,
        customPersonality,
        setCustomPersonality,
        handleFetchLinks,
        createChatbot,
        loading,
        CreateChatbotloading,
        UpdateChatbotloading,
        resetState,
        ChatbotData,
        setChatbotData,
        setAddedCharacters,
        AddedCharacters,
        RetrainChatbot,
        validResponse,
        isBaseUrl
      }}
    >
      {children}
    </DataSourcesContext.Provider>
  );
};

export const useDataSourcesState = () => {
  const context = useContext(DataSourcesContext);
  if (!context) {
    throw new Error(
      "useDataSourcesState must be used within a DataSourcesStateProvider"
    );
  }
  return context;
};
