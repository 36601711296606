import React, { useEffect, useState } from "react";
import AuthLayout from "./AuthLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../Context/AuthenticationContext";
import { toast } from "react-toastify";

const Login = () => {
  const { UserLogin, Loading,logInDataRef} = useAuthState();
  const [LogIncredentials, setLogIncredentials] = useState({
    email: "",
    password: "",
  });
  const [showMessage, setshowMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.showConfirmationMessage) {
      setshowMessage(true);
    }
  }, [location.state]);
 
  const [showVerificationMessasge, setshowVerificationMessasge] =
    useState(false);
  const handleLogInOnChange = (e) => {
    setLogIncredentials({
      ...LogIncredentials,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
     await UserLogin(LogIncredentials.email, LogIncredentials.password);
      if ( logInDataRef.current.responseCode === 200) {
        navigate('/my-chatbots')
      } else if (logInDataRef.current.responseCode === 400) {
        setshowMessage(false);
        setshowVerificationMessasge(true);
      }
    } catch (error) {
      // Handle Login error
      console.error("Login failed:", error);
      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <div>
      <AuthLayout
        heading={"Welcome back"}
        credentials={LogIncredentials}
        handleOnChange={handleLogInOnChange}
        handleSubmit={handleLogin}
        actionText={"LogIn"}
        check={"Don't have an account? "}
        fromSignInPage={true}
        actionLink={"/auth/signup"}
        showVerificationMessasge={showVerificationMessasge}
        showConfirmationMessasge={showMessage}
        Loading={Loading}
      />
    </div>
  );
};

export default Login;
