import React, { useState } from "react";
import BoxLayout from "./BoxLayout";
import { MdOutlineSubtitles } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMdContact } from "react-icons/io";
import { useSharedState } from "../../../Context/SharedStateContext";
import config from "../../../config";
import { toast } from "react-toastify";

const DynamicComponent = ({
  localLeadsUISettings,
  setLocalLeadsUISettings,
}) => {
  const enableButtonstatus = (e) => {
    const { name } = e.target;
    switch (name) {
      case "nameButton":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          nameButton: !prevValues.nameButton,
        }));
        break;
      case "emailButton":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          emailButton: !prevValues.emailButton,
        }));
        break;
      case "phoneButton":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          phoneButton: !prevValues.phoneButton,
        }));
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    switch (name) {
      case "title":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          title: value,
        }));
        break;
      case "name":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          name: value,
        }));
        break;
      case "email":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          email: value,
        }));
        break;
      case "phoneNo":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          phoneNo: value,
        }));
        break;
      default:
        break;
    }
  };

  const Reset = (e) => {
    const { name } = e.target;
    switch (name) {
      case "title":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          title: "Hi! Connect with me to discuss anything",
        }));
        break;
      case "name":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          name: "",
        }));
        break;
      case "email":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          email: "",
        }));
        break;
      case "phoneNo":
        setLocalLeadsUISettings((prevValues) => ({
          ...prevValues,
          phoneNo: "",
        }));
        break;
      default:
        break;
    }
  };
  return (
    <div className=" w-11/12  pt-2 rounded-md bg-white mx-auto">
      <div className="innerContent flex flex-col gap-4 mx-5 mt-5">
        <div className="template flex flex-col gap-4">
          <label className="text-md block font-semibold">Title</label>

          <div className="flex gap-4">
            <div className="w-1/2 h-10">
              <div className="flex relative ">
                <span className="mx-1 mt-[6px] absolute">
                  {" "}
                  <MdOutlineSubtitles className="text-3xl text-lime-800" />
                </span>
                <input
                  name="title"
                  value={localLeadsUISettings.title}
                  className=" w-full outline-none resize-none overflow-hidden h-10 pl-11 py-3 pr-3 border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  onChange={handleChange}
                />
              </div>
            </div>
            <button
              name="title"
              type="button"
              className=" px-4 py-[-2px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
              onClick={Reset}
            >
              Reset
            </button>
          </div>
          <div className="h-3 border-b border-lime-500/25 mb-2"></div>
        </div>
        <div className="template flex flex-col gap-4  ">
          <div className="flex justify-between w-full h-5">
            <label className="text-md block font-semibold">Name</label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                name="nameButton"
                type="checkbox"
                defaultChecked={localLeadsUISettings.nameButton}
                className="sr-only peer"
                onChange={enableButtonstatus}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-lime-300 dark:peer-focus:ring-lime-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-lime-800"></div>
            </label>
          </div>

          {localLeadsUISettings.nameButton && (
            <div className="flex gap-4">
              <div className="w-1/2 h-10">
                <div className="flex relative ">
                  <span className="mx-1 mt-[6px] absolute">
                    {" "}
                    <IoMdContact className="text-3xl text-lime-800" />
                  </span>
                  <input
                    name="name"
                    value={localLeadsUISettings.name}
                    placeholder="Name"
                    className=" w-full outline-none resize-none overflow-hidden h-10 pl-11 py-3 pr-3 border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button
                name="name"
                type="button"
                className=" px-4 py-[-2px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                onClick={Reset}
              >
                Reset
              </button>
            </div>
          )}
          <div className="h-3 border-b border-lime-500/25 mb-2"></div>
        </div>
        <div className="template flex flex-col gap-4">
          <div
            className="flex justify-between items-center
             w-full h-5"
          >
            <label className="text-md block font-semibold">Email</label>
            <label className="inline-flex items-center  cursor-pointer">
              <input
                name="emailButton"
                type="checkbox"
                defaultChecked={localLeadsUISettings.emailButton}
                className="sr-only peer"
                onChange={enableButtonstatus}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-lime-300 dark:peer-focus:ring-lime-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-lime-800"></div>
            </label>
          </div>
          {localLeadsUISettings.emailButton && (
            <div className="flex gap-4">
              <div className="w-1/2 h-10">
                <div className="flex relative ">
                  <span className="mx-1 mt-[6px] absolute">
                    {" "}
                    <MdEmail className="text-3xl text-lime-800" />
                  </span>
                  <input
                    name="email"
                    value={localLeadsUISettings.email}
                    placeholder="Email"
                    className=" w-full outline-none resize-none overflow-hidden h-10 pl-11 py-3 pr-3 border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button
                name="email"
                type="button"
                className=" px-4 py-[-2px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                onClick={Reset}
              >
                Reset
              </button>
            </div>
          )}
          <div className="h-3 border-b border-lime-500/25 mb-2"></div>
        </div>
        <div className="template flex flex-col gap-4">
          <div className="flex justify-between w-full h-5">
            <label className="text-md block font-semibold">Phone</label>
            <label className="inline-flex items-center  cursor-pointer">
              <input
                name="phoneButton"
                type="checkbox"
                defaultChecked={localLeadsUISettings.phoneButton}
                value=""
                className="sr-only peer"
                onChange={enableButtonstatus}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-lime-300 dark:peer-focus:ring-lime-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-lime-800"></div>
            </label>
          </div>
          {localLeadsUISettings.phoneButton && (
            <div className="flex gap-4">
              <div className="w-1/2 h-10">
                <div className="flex relative ">
                  <span className="mx-2 mt-[10px] absolute">
                    {" "}
                    <FaPhoneVolume className="text-2xl text-lime-800" />
                  </span>
                  <input
                    name="phoneNo"
                    value={localLeadsUISettings.phoneNo}
                    className=" w-full outline-none resize-none overflow-hidden h-10 pl-11 py-3 pr-3 border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button
                name="phoneNo"
                type="button"
                className=" px-4 py-[-2px] rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                onClick={Reset}
              >
                Reset
              </button>
            </div>
          )}
          <div className="h-3 border-b border-lime-500/25 mb-2"></div>
        </div>
      </div>
    </div>
  );
};

const LeadsUI = () => {
  const { customizeOptions,chatbotDetails } = useSharedState();
  const LeadsUISettings = customizeOptions.LeadsUI;
  const [localLeadsUISettings, setLocalLeadsUISettings] =
    useState(LeadsUISettings);
    const [Loading,setLoading]=useState(false);


  const saveSettings = async() => {
   setLoading(true);
    if (
      (!localLeadsUISettings.emailButton &&
        !localLeadsUISettings.phoneButton)
    ) {
     
      toast.error("Atleast one of Email Id or Phone Number is required");
      setLoading(false);
    } else {
      const requestbody={
        chatbotId: chatbotDetails.chatbotId,
    message: localLeadsUISettings.title,
    name: localLeadsUISettings.nameButton,
    emailId: localLeadsUISettings.emailButton,
    contactNumber: localLeadsUISettings.phoneButton
      }
      try{
        const dyanamicbackendUrl=config[process.env.NODE_ENV].backendUrl;
        const response=await fetch(`${dyanamicbackendUrl}freight-service.azurewebsites.net/api/newLeadsSettingRequest`,{
         method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify(requestbody)

        })
        if(response.ok){
          toast.success("Saved Successfully");
        }
        else
        {
          toast.error("Error while saving.");
        }
      }catch(e){
        console.error("Error in submission of leads"+e);
      }finally{
        setLoading(false);
      }
      
  };
}
  return (
    <div className="w-11/12 lg:w-4/5">
   
      <BoxLayout
        Section="LeadsUI"
        handleSaveSettings={saveSettings}
        Loading={Loading}
        dynamicComponent={
          <DynamicComponent
            localLeadsUISettings={localLeadsUISettings}
            setLocalLeadsUISettings={setLocalLeadsUISettings}
          />
        }
      />
    </div>
  );
};

export default LeadsUI;
