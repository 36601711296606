import React, { useEffect, useRef, useState } from "react";
import ImageCropper from "../../ImageCropper/ImageCropper";
import Chatbot from "../../ChatBot/Chatbot";
import BoxLayout from "./BoxLayout";
import { FaUpload } from "react-icons/fa";
import { useSharedState } from "../../../Context/SharedStateContext";
import { toast } from "react-toastify";
import config from '../../../config'

const DynamicComponent = ({ localChatUISettings, setLocalChatUISettings, showIntegrationPage }) => {

  const imageref = useRef();
  const iconref = useRef();

  const getBlob = (blob) => {
    setLocalChatUISettings((prevValues) => ({
      ...prevValues,
      blob: blob,
      imagePreview: URL.createObjectURL(blob),
    }));
  };
  const getBlob1 = (blob) => {
    setLocalChatUISettings((prevValues) => ({
      ...prevValues,
      blob1: blob,
      iconImage: URL.createObjectURL(blob),
    }));
  };

  const validateImg = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    const allowedImageTypes = ["image/png", "image/svg+xml", "image/jpeg"];
    if (!allowedImageTypes.includes(file.type))
      return alert(
        "Invalid file type. Please select a PNG, SVG, or JPEG image."
      );
    switch (name) {
      case "profile":
        if (file.size >= 1048576) {
          return alert("Max file size is 1mb");
        } else {
          setLocalChatUISettings((prevValues) => ({
            ...prevValues,
            imagePreview: URL.createObjectURL(file),
            imagePreviewChange: true
          }));
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              setLocalChatUISettings((prevValues) => ({
                ...prevValues,
                inputImg: reader.result,
              }));
            },
            false
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        }
        break;
      case "icon":
        if (file.size >= 1048576) {
          return alert("Max file size is 1mb");
        } else {
          setLocalChatUISettings((prevValues) => ({
            ...prevValues,
            iconImage: URL.createObjectURL(file),
            iconImageChange: true
          }));
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              setLocalChatUISettings((prevValues) => ({
                ...prevValues,
                inputImg1: reader.result,
              }));
            },
            false
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    switch (name) {
      case "initialMessage":
        const lines = value.split("\n");
        const ans = lines.map((line) => ({
          text: line,
          sender: "Chatbot",
        }));
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          initialMessages: value,
          textArray: ans,
        }));
        break;

      case "suggestedMessage":
        const suggestedLines = value.split("\n");
        const temp = [];
        for (let x in suggestedLines) temp.push(suggestedLines[x]);

        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          suggestedMessages: value,
          suggestedMessagearray: temp,
        }));
        break;
      case "iconImage":
        iconref.current.click();
        iconref.current.addEventListener("change", (e) => {
          validateImg(e);
        });
        break;

      case "profileImage":
        imageref.current.click();
        imageref.current.addEventListener("change", (e) => {
          validateImg(e);
        });
        break;
      case "Themes":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          Themes: value,
        }));
        break;
      case "Message":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          placeholder: value,
        }));
        break;
      case "name":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          name: value,
        }));
        break;
      case "TitleBarColor":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          TitleBarColor: value,
        }))
        break;
      case "colorPicker":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          colorPicker: value,
        }));
        break;
      case "bubblePicker":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          bubblePicker: value,
        }));
        break;
      case "bubblePosition":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          bubblePosition: value,
        }));
        break;

      default:
        break;
    }
  };

  const Reset = (event) => {
    event.preventDefault();
    const { name } = event.target;
    switch (name) {
      case "resetMessage":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          initialMessages: "Hi! What can I help you with?",
          textArray: [
            {
              text: "Hi! What can I help you with?",
              sender: "Chatbot",
            },
          ],
        }));

        break;
      case "colorPicker":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          colorPicker: "#4299e1",
        }));
        break;
      case "bubblePicker":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          bubblePicker: "#FFC0CB",
        }));
        break;
        case "TitleBarColor":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          TitleBarColor: "white",
        }));
        break;

      default:
        break;
    }
  };

  const removeImage = (event) => {
    event.preventDefault();
    const { name } = event.target;
    switch (name) {
      case "iconImage":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          iconImage: '',
          blob1: null,
          iconImageChange: true
        }));

        iconref.current.value = "";
        break;

      case "profileImage":
        setLocalChatUISettings((prevValues) => ({
          ...prevValues,
          imagePreview: '',
          blob: null,
          imagePreviewChange: true
        }));
        imageref.current.value = "";
        break;

      default:
        break;
    }
  };

  return (
    <div className=" w-full  py-2 mb-3 rounded-md bg-white mx-">
      <p className="ml-4 my-2"> Note: Applies when embedded on a website</p>

      <form>
        <div className="grid gap-2 sm:grid-cols-2">
          <div>
            <div className="flex flex-col gap-2">
              <div className=" mx-4 ">
                <div className="flex justify-between items-center my-3">
                  <label className=" font-semibold text-gray-700">
                    Initial Message
                  </label>
                  <button
                    name="resetMessage"
                    type="button"
                    className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                    onClick={Reset}
                  >
                    Reset
                  </button>
                </div>
                <textarea
                  name="initialMessage"
                  rows="2"
                  className="mt-1 block w-full rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  value={localChatUISettings.initialMessages}
                  onChange={handleChange}
                ></textarea>
                <p className="mt-1 text-sm text-zinc-500">
                  Enter each message in a new line
                </p>
              </div>

              <div className=" mx-4 my-3">
                <label className=" font-semibold text-gray-700">
                  Suggested Messages
                </label>
                <textarea
                  name="suggestedMessage"
                  rows="2"
                  className="mt-2 block w-full rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  placeholder="What is www.com"
                  value={localChatUISettings.suggestedMessages}
                  onChange={handleChange}
                ></textarea>
                <p className="mt-1 text-sm text-zinc-500">
                  Enter each message in a new line
                </p>
              </div>
              <div className=" mx-4 my-3">
                <label className=" font-semibold text-gray-700">
                  Message Placeholder
                </label>

                <input
                  name="Message"
                  className="mt-2 block w-full  text-sm rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  placeholder="Message"
                  value={localChatUISettings.placeholder}
                  onChange={handleChange}
                />
              </div>

              <div className=" mx-4 my-3 font-semibold text-gray-700">
                Theme
                <select
                  name="Themes"
                  className="mt-2 block w-full rounded-md  border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none font-medium "
                  value={localChatUISettings.Themes}
                  onChange={handleChange}
                >
                  <option value="light">light</option>
                  <option value="dark">dark</option>
                </select>
              </div>

              <div className=" mx-4 mb-4">
                <div className="flex justify-between items-center my-3">
                  <label className=" font-semibold text-gray-700">
                    Title Bar Color
                  </label>
                  <button
                    type="button"
                    name="TitleBarColor"
                    onClick={Reset}
                    className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                  >
                    Reset
                  </button>
                </div>
                <input
                  type="color"
                  name="TitleBarColor"
                  value={localChatUISettings.TitleBarColor}
                  onChange={handleChange}
                  className="w-8 bg-gray-300 cursor-pointer"
                />

              
              </div>

              <div className=" mx-4 my-2 flex flex-row items-center gap-4">
                <div className="w-[42px] h-[42px] bg-white rounded-full border-[2px] border-lime-700 flex justify-center items-center">
                  {localChatUISettings.imagePreview && < img
                    src={localChatUISettings.imagePreview}
                    alt="pic"
                    className="rounded-full w-[40px] h-[40px]"

                  />
                  }
                </div>

              

                <div className="flex flex-col gap-2 my-3">
                  <span className=" font-semibold text-zinc-700">
                    Profile picture
                  </span>
                  <div className="flex flex-row gap-1">
                    <input
                      type="file"
                      name="profile"
                      className=" border w-full mt-2 pl-5 border-lime-500/25 rounded-md py-[6px] hidden"
                      ref={imageref}
                      accept="image/png, image/svg+xml, image/jpeg"
                    />
                    <button
                      name="profileImage"
                      className="py-1 px-2 text-sm rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out flex gap-1.5 items-center"
                      onClick={handleChange}
                    >
                      <FaUpload size={15} className="inline" /> Upload image
                    </button>
                    <button
                      name="profileImage"
                      className="py-1 px-2 text-sm rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out"
                      onClick={removeImage}
                    >
                      Remove image
                    </button>
                  </div>
                  <span className=" text-xs text-zinc-500">
                    Supports JPG, PNG, and SVG files up to 1MB
                  </span>
                </div>
                {localChatUISettings["inputImg"] && (
                  <ImageCropper
                    getBlob={getBlob}
                    formValues={localChatUISettings}
                    setformValues={setLocalChatUISettings}
                    imageref={imageref}
                    profile={"profile"}
                  />
                )}
              </div>

              <div className=" mx-4 my-3">
                <label className="font-semibold text-gray-700">
                  Display Name
                </label>

                <input
                  name="name"
                  placeholder="Name"
                  className="mt-2 block w-full  text-sm rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  value={localChatUISettings.name}
                  onChange={handleChange}
                />
              </div>

              <div className=" mx-4 mb-2">
                <div className="flex justify-between items-center my-3">
                  <label className=" font-semibold text-gray-700">
                    User Message Color
                  </label>
                  <button
                    type="button"
                    name="colorPicker"
                    onClick={Reset}
                    className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                  >
                    Reset
                  </button>
                </div>
                <input
                  type="color"
                  name="colorPicker"
                  value={localChatUISettings.colorPicker}
                  onChange={handleChange}
                  className="w-8 bg-gray-300 cursor-pointer"
                />

                <p className="mt-3 text-sm font-medium text-gray-500">
                  **If the changes here don't show up immediately on your
                  website try clearing your browser cache or use incognito. (New
                  users will see the changes immediately)**
                </p>
              </div>

              <div className=" mx-4 my-2 flex flex-row items-center gap-4">
                <div className="w-[42px] h-[42px] bg-white rounded-full border-[2px] border-lime-700 flex justify-center items-center">
                  {localChatUISettings.iconImage && < img
                    src={localChatUISettings.iconImage}
                    alt="pic"
                    className="rounded-full w-[40px] h-[40px]"

                  />
                  }
                </div>
                <div className="flex flex-col gap-2 my-3">
                  <span className=" font-semibold text-zinc-700">
                    Chat icon
                  </span>
                  <div className="flex flex-row gap-1">
                    <input
                      type="file"
                      name="icon"
                      className=" border w-full mt-2 pl-5 border-lime-500/25 rounded-md py-[6px] hidden"
                      ref={iconref}
                      accept="image/png, image/svg,image/jpg"
                    />
                    <button
                      name="iconImage"
                      className="py-1 px-2 text-sm  rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out flex gap-1.5 items-center"
                      onClick={handleChange}
                    >
                      <FaUpload size={15} className="inline" /> Upload image
                    </button>
                    <button
                      name="iconImage"
                      className="py-1 px-2 text-sm   rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-medium transition duration-200 ease-in-out"
                      onClick={removeImage}
                    >
                      Remove image
                    </button>
                  </div>
                  <span className=" text-xs text-zinc-500">
                    Supports JPG, PNG, and SVG files up to 1MB
                  </span>
                </div>
                {localChatUISettings["inputImg1"] && (
                  <ImageCropper
                    getBlob={getBlob1}
                    formValues={localChatUISettings}
                    setformValues={setLocalChatUISettings}
                    imageref={iconref}
                    profile={"icon"}
                  />
                )}
              </div>
              <div className=" mx-4 mb-2">
                <div className="flex justify-between items-center my-3">
                  <label className=" text- font-semibold text-gray-700">
                    Chat Bubble Button Color
                  </label>
                  <button
                    type="button"
                    name="bubblePicker"
                    onClick={Reset}
                    className="py-1 px-4 rounded-md bg-lime-700 text-white border-2 border-transparent hover:border-lime-800 hover:bg-transparent hover:text-lime-800 font-semibold transition duration-200 ease-in-out"
                  >
                    Reset
                  </button>
                </div>
                <input
                  type="color"
                  name="bubblePicker"
                  value={localChatUISettings.bubblePicker}
                  onChange={handleChange}
                  className="w-8 bg-gray-300 cursor-pointer"
                />
              </div>

              <div className=" mx-4 text-sm font-medium text-gray-700 mb-2">
                Align Chat Bubble Button
                <select
                  name="bubblePosition"
                  className="mt-2 block w-full rounded-md border border-lime-500/25 shadow-sm px-3 py-2 text-gray-800 focus:border-lime-800 focus:ring-4 focus:ring-lime-500/10 focus:outline-none"
                  value={localChatUISettings.bubblePosition}
                  onChange={handleChange}
                >
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>
              </div>
            </div>
          </div>
          <div className={`rightChatbot flex justify-center  ${showIntegrationPage ? 'hidden' : ''}`}>
            <Chatbot
              localChatUISettings={localChatUISettings}
              setLocalChatUISettings={setLocalChatUISettings}
              chatinterface={false}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const ChatUI = () => {
  const {
    customizeOptions,
    chatbotDetails,
    getChatbotDetails,
    showIntegrationPage,
  } = useSharedState();
  const CustomerId = localStorage.getItem('user');
  const ChatUISettings = customizeOptions.ChatUI;
  const [localChatUISettings, setLocalChatUISettings] =
    useState(ChatUISettings);
  const [Loading, setLoading] = useState(false);
  const saveSettings = async () => {
    // saveChatUIsettings
    setLoading(true);
    const dyanamicBotQueryUrl = config[process.env.NODE_ENV].BotQueryUrl
    const formData = new FormData();
    formData.append("chatbotId", chatbotDetails.chatbotId);
    formData.append("customerId", CustomerId);
    formData.append("initialMessages", localChatUISettings.initialMessages);
    formData.append("suggestedMessages", localChatUISettings.suggestedMessages);
    formData.append("messagePlaceholder", localChatUISettings.placeholder);
    formData.append("theme", localChatUISettings.Themes);
    formData.append("chatbotProfilePic", localChatUISettings.blob);
    formData.append("chatbotDisplayName", localChatUISettings.name);
    formData.append("userMessageColor", localChatUISettings.colorPicker);
    formData.append("chatbotIcon", localChatUISettings.blob1);
    formData.append("chatBubbleButtonColor", localChatUISettings.bubblePicker);
    formData.append(
      "chatBubbleButtonAlignment",
      localChatUISettings.bubblePosition
    );
    formData.append("chatbotProfilePicChange", localChatUISettings.imagePreviewChange);
    formData.append("chatbotIconChange", localChatUISettings.iconImageChange);
    formData.append("titleBarColor",localChatUISettings.TitleBarColor);

    try {
      const response = await fetch(
        `${dyanamicBotQueryUrl}update-chatbot-ui/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {

        toast.success("ChatUI updated successfully");
        getChatbotDetails(CustomerId);
      } else {
        toast.error("Error while updating ChatUi")
      }
    } catch (error) {
      toast.error("Error while updating ChatUi")

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chatbotDetails && chatbotDetails.chatbotUI) {
      setLocalChatUISettings((prevSettings) => {
        const initialMessages =
          chatbotDetails.chatbotUI.initialMessage ||
          prevSettings?.ChatUI?.initialMessages;
        const suggestedMessages = chatbotDetails?.chatbotUI?.suggestedMessages;
        const initialMessagesArray = initialMessages
          ? initialMessages.split(/\r?\n/)
          : [];
        const suggestedMessagearray = suggestedMessages
          ? suggestedMessages.split(/\r?\n/)
          : [];

        return {
          ...prevSettings,
          initialMessages,
          suggestedMessages,
          textArray: initialMessagesArray.map((message) => ({
            text: message.trim(),
            role: "ai",
          })),
          suggestedMessagearray: suggestedMessagearray.map((message) =>
            message.trim()
          ), // Fixed this line
          placeholder:
            chatbotDetails.chatbotUI.messagePlaceholder.trim() ? chatbotDetails.chatbotUI.messagePlaceholder :
              prevSettings?.ChatUI?.placeholder === undefined ? '' : prevSettings?.ChatUI?.placeholder,
          Themes:
            chatbotDetails.chatbotUI.theme || prevSettings?.ChatUI?.Themes,
          name:
            chatbotDetails.chatbotUI.chatbotDisplayName.trim() !== ''
              ? chatbotDetails.chatbotUI.chatbotDisplayName
              : prevSettings?.ChatUI?.name === undefined ? '' : prevSettings?.ChatUI?.name,
          colorPicker:
            chatbotDetails.chatbotUI.userMessageColor ? chatbotDetails.chatbotUI.userMessageColor :
              prevSettings?.ChatUI?.colorPicker === undefined ? '' : prevSettings?.ChatUI?.colorPicker,
          bubblePicker:
            chatbotDetails.chatbotUI.chatBubbleButtonColor ? chatbotDetails.chatbotUI.chatBubbleButtonColor :
              prevSettings?.ChatUI?.bubblePicker === undefined ? '' : prevSettings?.ChatUI?.bubblePicker,
              TitleBarColor:
            chatbotDetails.chatbotUI.titleBarColor ? chatbotDetails.chatbotUI.titleBarColor :
              prevSettings?.ChatUI?.TitleBarColor === undefined ? '' : prevSettings?.ChatUI?.TitleBarColor,
          bubblePosition:
            chatbotDetails.chatbotUI.chatBubbleButtonAlignment ||
            prevSettings?.ChatUI?.bubblePosition,
          imagePreview:
            chatbotDetails.chatbotUI.chatbotProfilePic ? chatbotDetails.chatbotUI.chatbotProfilePic :
              prevSettings?.ChatUI?.imagePreview === undefined ? '' : prevSettings?.ChatUI?.imagePreview,
          iconImage:
            chatbotDetails.chatbotUI.chatbotIcon ? chatbotDetails.chatbotUI.chatbotIcon :
              prevSettings?.ChatUI?.iconImage === undefined ? '' : prevSettings?.ChatUI?.iconImage,
        };
      });
    }
  }, [chatbotDetails]);

  return (
    <div className="w-11/12 lg:w-4/5">
      <BoxLayout
        Section="ChatUI"
        handleSaveSettings={saveSettings}
        Loading={Loading}
        dynamicComponent={
          <DynamicComponent
            localChatUISettings={localChatUISettings}
            setLocalChatUISettings={setLocalChatUISettings}
            showIntegrationPage={showIntegrationPage}
          />
        }
      />
    </div>
  );
};

export default ChatUI;
