
const config = {
    development: {
      backendUrl:'https://',
      BotQueryUrl:'http://34.224.93.99:5000/',

    },
    production: {
      backendUrl: 'https://',
      BotQueryUrl:'https://chatcells.ai/api/',
    }
  };
  
export default config;
  