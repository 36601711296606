import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { ImSpinner9 } from "react-icons/im";
import { useSharedState } from '../../Context/SharedStateContext';
import Select from 'react-select'
import config from '../../config'
import { toast } from 'react-toastify';
import { useAuthState } from '../../Context/AuthenticationContext';

const Consultation = ({ showConsultationPage }) => {
  const { setPagesState } = useSharedState();
  const [selectedValue,setselectedValue]=useState({ value: '', label: 'Select Industry', name: 'industry' });
  const [Consultationform, setConsultationfrom] = useState({
    name: '',
    email: '',
    phoneNo: '',
    industry: ''

  })
  const dyanamicbackendUrl= config[process.env.NODE_ENV].backendUrl;
  const industries = [{ value: '', label: 'Select Industry', name: 'industry' }, { value: 'Software', label: 'Software', name: 'industry' }, { value: 'Analytics', label: 'Analytics', name: 'industry' }, { value: 'AI', label: 'AI', name: 'industry' }, { value: 'Development', label: 'Development', name: 'industry' }]
  const [Error, setError] = useState({
    name: '',
    email: '',
    industry: ''
  })
  const [buttonStatus, setButtonStatus] = useState(false);
  const {Loading, setLoading} = useAuthState();
  const isBlank = () => {

    setError({
      name: '',
      email: '',
      industry:''
    })

    if (Consultationform.name === '') {
      setError((prev) => ({
        ...prev,
        name: 'Please add the Name'
      }))
      return true;
    }
    else if (Consultationform.email === '') {
      setError((prev) => ({
        ...prev,
        email: 'Please add an email'
      }))
      return true;
    }
    else if (Consultationform.industry === '') {
      setError((prev) => ({
        ...prev,
        industry: 'Please select an industry'
      }))
      return true;
    }
    return false;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      [name]: '',
    })
    setConsultationfrom((prev) => ({
      ...prev,
      [name]: value
    }))
    setButtonStatus(true);
  }
  const handleSingleselect = (selectedOption) => {
    setButtonStatus(true);
    setError({
      industry: '',
    })
    setselectedValue(selectedOption);
    setConsultationfrom((prev) => ({
      ...prev,
      industry: selectedOption.value
    }))
  }

  const validateEmail = (email) => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isBlank())
      return;

    if (!validateEmail(Consultationform.email)) {
      setError((prev) => ({
        ...prev,
        email: 'Please Enter valid email address..'
      }))
      return;
    }
    setLoading(true);
    const bodyrequest={
      name:Consultationform.name,
      emailId:Consultationform.email,
      contactNumber:Consultationform.phoneNo,
      industry:Consultationform.industry
    }
    try {
      const url = `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/newconsultationrequest`
      const response = await fetch(url, {
        method: 'POST',
        headers:{'Content-Type':'application/json'},
        body: JSON.stringify(bodyrequest),
      })
      // const data = await response.json();
      if (response.ok) {
        setLoading(false);
        setConsultationfrom({
          name: '',
          email: '',
          phoneNo: '',
          industry: ''
      
        })
        setselectedValue({ value: '', label: 'Select Industry', name: 'industry' })
        toast.success('Thanks for your interest. We have sent your request to the concerned team. They will contact you shortly.')
      }
      else {
        setLoading(false);
        toast.error('Error occured while submitting..')
      }
    } catch (error) {
      setLoading(false);
      setButtonStatus((prev) => (!prev));
      console.error({ Error: error.message });
      toast.error('Error occured while submitting..')
    }

  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0.375rem',
      boxShadow: state.isFocused ? '0 0 0 2px rgba(132, 204, 22, 0.25)' : 'none',
      borderColor: state.isFocused ? 'rgb(54 83 20 / 1)' : 'rgb(132, 204, 22, 0.25)',
      margin:'4px',
      color: '#4B5563',
      '&:hover': {
        borderColor: 'rgb(54 83 20 / 1)', // border-lime-500/25
      },
    }),

  };

  return (
    <>{showConsultationPage && (
      <div
        id="popup-modal"
        className="fixed top-0  right-0  left-0 z-50 flex justify-center items-center w-screen h-full bg-black bg-opacity-80 overflow-y-auto"
      >
        <div className="bg-white  overflow-y-auto relative rounded-lg flex flex-col  items-center gap-2 shadow-lg w-[75%] h-[70%] p-4 md:w-[55%] lg:w-[42%]">
          <button
            className="absolute top-0 right-0 mt-1 mr-1 z-50  hover:bg-lime-800 hover:text-white text-lime-800 bg-white shadow-lg rounded-full p-2"
            onClick={() => {
              setPagesState((prev) => ({
                ...prev,
                showConsultationPage: !prev.showConsultationPage
              }))
            }}
          >
            <RxCross2 className="text-bold" />
          </button>
          <div className='flex flex-col  m-4'>
            <h1 className="heading flex items-center py-2">
              <span className="flex-1 border-b border-lime-500/25 mx-4"></span>
              <span className="text-center text-xl font-medium text-gray-800 opacity-80">Free Consultation</span>
              <span className="flex-1 border-b border-lime-500/25 mx-4"></span>
            </h1>
            <div className='flex justify-center items-center'>
              <span className='text-lime-800 font-medium text-sm text-center'> Please provide your contact details and we will connect with you shortly</span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className='w-full flex justify-center items-center'>
            <div className=' w-full lg:w-[80%] flex flex-col'>
              <div className=" mx-4 my-2">
                <label htmlFor="name" className="inline text-sm font-medium text-gray-700">
                  Name
                </label>
                <span className='text-[#ff130b] pl-1'>*</span>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className={`mt-1 block w-full rounded-md ${Loading ? 'bg-[#f4f4f4]' : ''}   shadow-sm px-3 py-1.5 text-gray-800    border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
                  placeholder="Enter your name"
                  value={Consultationform.name}
                  onChange={handleChange}
                  disabled={Loading}


                />
                {Error.name && <p className='text-[#ff130b] text-sm opacity-80 m-0.5'>{Error.name}</p>}
              </div>
              <div className=" mx-4 my-2">
                <label htmlFor="email" className="inline text-sm font-medium text-gray-700">
                  Email
                </label>
                <span className='text-[#ff130b] pl-1'>*</span>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className={`mt-1 block w-full rounded-md ${Loading ? 'bg-[#f4f4f4]' : ''}   shadow-sm px-3 py-1.5 text-gray-800    border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
                  placeholder="Enter your email"
                  value={Consultationform.email}
                  onChange={handleChange}
                  disabled={Loading}


                />
                {Error.email && <p className='text-[#ff130b] text-sm opacity-80 m-0.5'>{Error.email}</p>}
              </div>
              <div className=" mx-4 my-2">
                <label htmlFor="phoneNo" className="inline text-sm font-medium text-gray-700">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="phoneNo"
                  name="phoneNo"
                  className={`mt-1 block w-full rounded-md ${Loading ? 'bg-[#f4f4f4]' : ''}   shadow-sm px-3 py-1.5 text-gray-800    border-[1px] border-solid focus:border-lime-700 border-lime-500/25 focus:ring-4 focus:ring-lime-500/10 focus:outline-none`}
                  placeholder="Enter your Contact Number"
                  value={Consultationform.phoneNo}
                  onChange={handleChange}
                  disabled={Loading}


                />
              </div>
              <div className="mx-4 mt-2 mb-6">
                <label htmlFor="industry" className="inline text-sm mb-2 font-medium text-gray-700">
                  Industry
                </label>
                <span className='text-[#ff130b] pl-1'>*</span>

                <Select
                  name='industry'
                  defaultValue={industries[0]}
                  value={selectedValue}
                  options={industries}
                  onChange={(selectedOption) => handleSingleselect(selectedOption)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgb(132 204 22 / 0.25)',
                      primary: '#4D7C0F',

                    },
                  })}
                  styles={customStyles}
                  isDisabled={Loading}
                  isSearchable={true}


                />
                {Error.industry && <p className='text-[#ff130b] text-sm opacity-80 m-0.5'>{Error.industry}</p>}

              </div>
              <div className="flex  justify-between mt-[-8px] sm:mt-4">

                <div className="flex justify-center  w-full px-2 py-1.5">

                  <div className="button justify-center items-center text-md font-semibold gap-4 flex flex-col sm:flex-row">


                    {/* Submit Button */}
                    <button
  disabled={!buttonStatus || Loading}
  type="submit"
  className={`px-4 py-1 rounded-md text-white border-2 border-transparent font-semibold transition duration-200 ease-in-out ${
    buttonStatus && !Loading
      ? "bg-lime-700 hover:border-lime-800 hover:bg-transparent hover:text-lime-800"
      : "bg-lime-700 opacity-50 cursor-not-allowed"
  }`}
>
  {Loading ? 'Please wait...' : 'Submit'}
  {Loading && <ImSpinner9 className="animate-spin inline ml-1 text-white text-xl" />}
</button>

                   
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    )}
    </>
  );
}

export default Consultation;
