import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import config from '../../src/config'
import { useDataSourcesState } from "./DataSourcesContext";
const SharedStateContext = createContext();

export const SharedStateProvider = ({ children }) => {
  const [Chatbots, setChatbots] = useState([]);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [chatbotDetails, setChatbotDetails] = useState([]);
  const [loadingChatbots, setLoadingChatbots] = useState(false);
  const [loadingChatbotDetails, setLoadingChatbotDetails] = useState(false);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [Conversations, setConversations] = useState([]);
  const [OpenedConversations, setOpenedConversations] = useState([])
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const dyanamicbackendUrl=config[process.env.NODE_ENV].backendUrl;
  const customerId=localStorage.getItem('user');
  const { customPersonality, selectedPersonality } = useDataSourcesState();
  const [pagesState, setPagesState] = useState({
    showIntegrationPage: false,
    showSharePage: false,
    showdeletePage: false,
    showConsultationPage: false,
  });
  const getChatbots = async (CustomerId) => {
    setLoadingChatbots(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/getallchatbots`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customerId: CustomerId }),
        }
      );
      if (!response?.body) {
        return;
      }
      const chatbots = await response.json();
      setChatbots(chatbots);
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error fetching chatbots");
    } finally {
      setLoadingChatbots(false); // Set loading state to false regardless of success or failure
    }
  };
  const getChatbotDetails = async (CustomerId) => {
    setLoadingChatbotDetails(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/getchatbotdetails`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId: CustomerId,
            chatbotId: selectedChatbot?.chatbotId,
          }),
        }
      );
      if (!response?.body) {
        return;
      }
      const data = await response.json();
      setChatbotDetails(data);
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error fetching chatbot details");
    } finally {
      setLoadingChatbotDetails(false);
    }
  };
  const getConversations = async (source, startDate, endDate) => {
    setLoadingConversations(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/getconversations`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chatbotId: selectedChatbot?.chatbotId,
            fromDate: startDate,
            toDate: endDate,
            source: source,
          }),
        }
      );
      if (!response?.body) {
        return;
      }
      const data = await response.json();
      
      setConversations(data);
      // setOpenedConversations(data[0]?.actualConversations);
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error fetching chat logs");
    } finally {
      setLoadingConversations(false);
    }
  };

  const getModel = async (model,chatbotId) => {
    // setLoadingChatbots(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/updatechatbotmodel`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chatbotId:chatbotId,
            model:model.model,
            temperature:model.Temperature,
            prompt:model.Prompt,
            personality:customPersonality ? customPersonality : selectedPersonality?.name

           }),
        }
      );
      if (response.ok) {
        toast.success('Successfully updated Model');
        getChatbotDetails(customerId);
      }
      else{
        console.error({error:"Error fetching chatbots" });
      toast.error("Error while updating model.");
      }
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error while updating model.");
    } finally {
      setLoadingChatbots(false); // Set loading state to false regardless of success or failure
    }
  };

  const getGeneral = async (general,chatbotId) => {
    // setLoadingChatbots(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/updatechatbotgeneral`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chatbotId:chatbotId,
            chatbotName:general.Name,
            visibility:general.Visibility,
            rateLimitMessages:general.MessageLimit,
            rateLimitTime:general.Time,
            rateLimitCrossMessage:general.ShowMessage,
            domains:general.Domains

           }),
        }
      );
      if (response.ok) {
        toast.success('Successfully updated General');
        getChatbotDetails(customerId);
      }
      else{
        console.error({error:"Error fetching chatbots" });
      toast.error("Error while updating general");
      }
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error while updating general");
    } finally {
      setLoadingChatbots(false); // Set loading state to false regardless of success or failure
    }
  };

  const DeleteChatbot = async (chatbotId) => {
    // setLoadingChatbots(true);
    try {
      const response = await fetch(
        `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/deleteChatbot`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chatbotId:chatbotId,
           }),
        }
      );
      if (response.ok) {
        toast.success('Successfully deleted chatbot');
        getChatbotDetails(customerId);
      }
      else{
        console.error({error:"Error while deleting chatbot" });
      toast.error("Error while deleting chatbot");
      }
    } catch (error) {
      console.error({ error: error.message });
      toast.error("Error while deleting chatbot");
    } finally {
      setLoadingChatbots(false); // Set loading state to false regardless of success or failure
    }
  };


  const handleOpenedConversations = (data) => {
    setOpenedConversations([
      { AIMessage: chatbotDetails?.chatbotUI?.initialMessage },
      ...data
    ]);
  }

  const [customizeOptions, setCustomizeOptions] = useState({
    General: {
      Name: "Chatcells",
      Visibility: "private",
      MessageLimit: 20,
      Time: 240,
      ShowMessage: "Too many messages in a row",
      Domains:""
    },
    Model: {
      model: "gpt-3.5-trubo",
      Prompt:
        'I want you to act as a support agent. Your name is "AI Assistant". You will provide me with answers from the given info. If the answer is not included, say exactly "Hmm, I am not sure." and stop after that. Refuse to answer any question not about the info. Never break character.',
      Language: "English",
      Temperature: 0,
      LastModifiedDate: "",
    },
    ChatUI: {
      initialMessages: "",
      textArray: [
        {
          text: "",
          role: "ai",
        },
      ],
      suggestedMessages: " ",
      suggestedMessagearray: [],
      placeholder: " ",
      Themes: "light",
      name: " ",
      colorPicker: " ",
      bubblePicker: " ",
      bubblePosition: "right",
      blob: null,
      blob1: null,
      inputImg: "",
      inputImg1: "",
      imagePreview: "",
      iconImage: "",
      imagePreviewChange:false,
      iconImageChange:false,
      TitleBarColor:""
    },
    LeadsUI: {
      title: "Hi! Connect with me to discuss anything",
      name: "",
      phoneNo: "",
      email: "",
      nameButton: true,
      emailButton: true,
      phoneButton: true,
    },
  });

  const saveGeneralSettings = (newGeneralSettings) => {
    setCustomizeOptions((prevSettings) => ({
      ...prevSettings,
      General: {
        ...prevSettings.General,
        ...newGeneralSettings,
      },
    }));
  };
  const saveChatUISettings = (newChatUISettings) => {
    setCustomizeOptions((prevSettings) => ({
      ...prevSettings,
      ChatUI: {
        ...prevSettings.ChatUI,
        ...newChatUISettings,
      },
    }));
  };

  const saveModelSettings = (newModelSettings) => {
    setCustomizeOptions((prevSettings) => ({
      ...prevSettings,
      Model: {
        ...prevSettings.Model,
        ...newModelSettings,
      },
    }));
  };

  return (
    <SharedStateContext.Provider
      value={{
        customizeOptions,
        saveGeneralSettings,
        saveModelSettings,
        saveChatUISettings,
        Chatbots,
        getChatbots,
        getChatbotDetails,
        getModel,
        getGeneral,
        DeleteChatbot,
        loadingChatbots,
        loadingChatbotDetails,
        selectedChatbot,
        setSelectedChatbot,
        chatbotDetails,
        getConversations,
        handleOpenedConversations,
        OpenedConversations,
        loadingConversations,
        Conversations,
        pagesState,
        setPagesState,
        setIsSidebarOpen,
        isSidebarOpen
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error("useSharedState must be used within a SharedStateProvider");
  }
  return context;
};
