import React, { useEffect } from "react";
import { ImSpinner9 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from '../../../src/config'

const ActivationPage = () => {
  const navigate = useNavigate();
  const dyanamicbackendUrl=config[process.env.NODE_ENV].backendUrl;
  useEffect(() => {
    //extracting attribute from url
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const code = urlParams.get("code");
    const purpose = urlParams.get("purpose");
    const verifyCode = async () => {
      try {
        const response = await fetch(
          `${dyanamicbackendUrl}freight-service.azurewebsites.net/api/verifycode?id=${id}&code=${code}&purpose=${purpose}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" }
          }
        );
        const data2 = await response.json();
        if (data2.responseCode === 200) {
          toast.success('User Verified Successfully');
          if(purpose==='Activation'){
          navigate("/auth/login");
          }
          else if(purpose==='Password'){
            navigate('/auth/reset-password')
          }
        }
        else{
        toast.error("Error occurred while verifying code");
        }
      } catch (error) {
        console.error(error.message);
        toast.error("Internal Error")
      }
    };
    verifyCode();
  }, [navigate,dyanamicbackendUrl]);
  return (
    <div className="w-full min-h-screen  relative">
      <div className="absolute w-full bg-black opacity-70 h-full"></div>

      <div className=" flex flex-col justify-center items-center gap-8 relative">
        <div className="flex flex-col w-1/3 gap-10 justify-center items-center absolute top-40 bg-white border-lime-500/25 rounded-lg p-5 ">
          <h2 className="text-3xl font-semibold text-lime-700 text-center mt-5">
          Verifying Email
          </h2>
          <div className="flex flex-col gap-5 justify-center items-center border-lime-500/25 py-8 px-6  rounded-2xl">
            <h4 className="text-lg text-lime-700">Please wait...</h4>
            <ImSpinner9 className="animate-spin text-lime-700 text-4xl " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationPage;
